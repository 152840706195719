<template>
  <Preloader />
  <Header />
  <router-view />
</template>

<script>
import Header from '@/components/layout/Header.vue'
import Preloader from '@/components/layout/Preloader'
export default {
  name: 'App',
  components: {
    Header,Preloader
  }
}
</script> 