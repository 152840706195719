<template>
    <!-- Common Banner Area -->
    <BookingPassengersBanner />

    <!-- Booking Submission Areas -->
    <BookingPassengers />

</template>
  
<script>
// @ is an alias to /src
import BookingPassengersBanner from '@/components/booking-passengers/BookingPassengersBanner.vue'
import BookingPassengers from '@/components/booking-passengers/BookingPassengers.vue'
export default {
    name: 'FlightSearchResultView',
    components: {
        BookingPassengersBanner, BookingPassengers
    }
}
</script>