<template>
    <section id="tour_booking_submission" class="section_padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="clearfix mb20" id="flight-selected">
                        <div class="itinerary-content mb30">
                            <div v-if="this.tripType != 'multi_city'" class="row no-margin align-items-center"
                                id="itinerary-content-outbound-header">
                                <div class="col-md-6 col-6">
                                    <div class="inline-middle">
                                        <h4 class="new-bold-light bold mb0 pb-2">{{ $t('Departure Flight') }}</h4>
                                        <span class="dep-date font-14">
                                            {{ $t(new
                                                Date(this.searchData.date).toLocaleDateString('en-US', {
                                                    month: 'long'
                                                })) }}
                                            {{ new Date(this.searchData.date).toLocaleDateString('en-US',
                                                { day: 'numeric' }) }}
                                            {{ new Date(this.searchData.date).getFullYear() }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="row no-margin align-items-center" id="itinerary-content-outbound-header">
                                <div class="col-md-6 col-6">
                                    <div class="inline-middle">
                                        <h4 class="new-bold-light bold mb0 pb-2">{{ $t('Flight details') }}</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="row  align-items-center mb-3">
                                <div class="col-md-5">
                                    <div class="p10 bg-white" id="itinerary-content-outbound-map">
                                        <div id="outbound-map" class="flight-map">
                                            <img :src="this.getOutboundImg">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-7">
                                    <div class="departure-itinerary">
                                        <div class="itinerary">
                                        </div>
                                        <div class="clearfix summary"> </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="this.tripType != 'multi_city' && this.tripType != 'oneway'" class="col-md-12">
                    <div class="clearfix mb20" id="flight-selected">
                        <div class="itinerary-content ib-con mb20">
                            <div class="row no-margin align-items-center">
                                <div class="col-md-6 col-6">
                                    <div class="inline-middle">
                                        <h4 class="new-bold-light bold  mb0 pb-2">{{ $t('Return Flight') }}</h4>
                                        <span class="arr-date font-14">
                                            {{ $t(new
                                                Date(this.searchData.ret_date).toLocaleDateString('en-US', {
                                                    month: 'long'
                                                })) }}
                                            {{ new Date(this.searchData.ret_date).toLocaleDateString('en-US',
                                                { day: 'numeric' }) }}
                                            {{ new Date(this.searchData.ret_date).getFullYear() }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row align-items-center mb-3">
                                <div class="col-md-5">
                                    <div class="p10 bg-white">
                                        <div id="inbound-map" class="map">
                                            <img :src="this.getInboundImg">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-7">
                                    <div class="arrival-itinerary ib-con">
                                        <div class="itinerary">
                                        </div>
                                        <div class="clearfix summary"> </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="this.allowed_to_request_hotel_reservation == 1 && this.essential_guest == 1 && this.is_self_paying == 0"
                    class="col-md-12">
                    <div class="clearfix mb20">
                        <div class="itinerary-content ib-con mb20">
                            <div class="row no-margin align-items-center">
                                <div class="col-md-6 col-6">
                                    <div class="inline-middle">
                                        <h4 class="new-bold-light bold  mb0 pb-2">{{ $t('Hotels') }}</h4>

                                    </div>
                                </div>
                            </div>
                            <div class="row align-items-center mb-3">
                                <div class="col-md-5">
                                    <div class="p10 bg-white">
                                        <div id="inbound-map" class="map">
                                            <img :src="this.hotel_img">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-7">
                                    <div class="row p10 pt-0 pb-0">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label>{{ $t('Hotel name') }}</label>
                                                <select v-model="hotel_id" @change="changeHotel" class="form-control"
                                                    :disabled="allowed_to_choose_hotel == 1 ? false : true">
                                                    <option v-for="hotel in hotels" :value="hotel.id">{{
                                                        hotel.hotel_name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="mb-3">
                                                <h4 class="font-size-18 mb-3 color-label">
                                                    <span class="note">{{ $t('Note') }} :
                                                    </span>
                                                    {{ $t('You are eligible for') }}
                                                    {{ hotel_conditions.free_duration }} x
                                                    {{ $t('free nights between') }}
                                                    {{ formattedDateRange(hotel_conditions.free_reservation_start_date,
                                                        hotel_conditions.free_reservation_end_date) }}
                                                </h4>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>{{ $t('Free reservation start date') }}</label>
                                                <input v-if="this.tripType != 'oneway'" type="text" class="form-control"
                                                    :value="entry_hotel_date" disabled>
                                                <VueDatePicker v-else v-model="entry_date" :enable-time-picker="false"
                                                    :min-date="formattedDate(this.hotel_conditions.reservation_start_date)"
                                                    :max-date="this.departure_time_for_riyadh"
                                                    :start-date="formattedDate(this.hotel_conditions.reservation_start_date)"
                                                    prevent-min-max-navigation @internal-model-change="changeEntry"
                                                    :placeholder="$t('select2')" />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>{{ $t('Free reservation end date') }}</label>
                                                <input type="text" class="form-control" :value="exit_hotel_date"
                                                    disabled>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label>
                                                    {{
                                                        $t('Room type')
                                                    }}
                                                </label>
                                                <select class="form-control" v-model="room_type">
                                                    <option value="">{{ $t('select2') }}</option>
                                                    <option value="King bed">{{ $t('King bed') }}</option>
                                                    <option value="Twin beds">{{ $t('Twin beds') }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="need_extra_end" class="row p10 pt-0 pb-0">
                                <div class="col-md-12 pl-5 pr-5">
                                    <hr>
                                </div>
                                <div class="col-md-12 text-center mt-3 mb-3">
                                    <div class="mb-3">
                                        <h4 class="font-size-18 mb-3 text-danger">
                                            <span class="note">{{ $t('Note') }} :
                                            </span>
                                            {{ $t('Saudi Cup will not cover extra nights') }}
                                        </h4>
                                    </div>
                                    <a v-if="confirm == 1 && allowed_to_request_extra_nights == 0"
                                        href="javascript:void(0);" @click="this.confirm = 0" class="bold mb-1">
                                        {{ $t('back') }}
                                        <i class="fl-30-ico fl-fare-rules-green"></i>
                                    </a>
                                </div>
                                <div v-if="confirm == 1 && allowed_to_request_extra_nights == 1" class="col-md-12 mb-3">
                                    <div class="form-group">
                                        <label>
                                            {{
                                                $t('do you want to book by yourself or need assistance')
                                            }}
                                        </label>
                                        <a href="javascript:void(0);" @click="this.confirm = 0"
                                            class="pull-right bold mb-1">
                                            {{ $t('back') }}
                                            <i class="fl-30-ico fl-fare-rules-green"></i>
                                        </a>
                                        <select class="form-control" v-model="need_assistance">
                                            <option value="">{{ $t('select2') }}</option>
                                            <option value="0">{{ $t('I want book by myself') }}</option>
                                            <option value="1"
                                                :disabled="allowed_to_request_extra_nights == 0 ? true : false">
                                                {{
                                                    $t('I need assistance') }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div v-if="need_assistance == 1 && confirm == 1" class="row p10 pt-0 pb-0">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>{{ $t('Number of nights') }}</label>
                                        <input type="text" class="form-control" :value="count_extra_end" disabled>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>{{ $t('Check-in date') }}</label>
                                        <input type="text" class="form-control" :value="exit_hotel_date" disabled>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>{{ $t('Check-out date') }}</label>
                                        <input type="text" class="form-control" :value="end_extra_date" disabled>
                                    </div>
                                </div>
                                <div class="col-md-12 mb-3">
                                    <div class="form-group">
                                        <label>
                                            {{
                                                $t('Room type')
                                            }}
                                        </label>
                                        <select class="form-control" v-model="extra_room_type">
                                            <option value="">{{ $t('select2') }}</option>
                                            <option value="King bed">{{ $t('King bed') }}</option>
                                            <option value="Twin beds">{{ $t('Twin beds') }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div v-if="confirm == 0 && need_extra_end" class="col-md-12 text-center mb-4">
                                <button type="button" class="btn btn_theme_two pl-0 pr-0 btn_md w-15 mr-5 ml-5">
                                    <h5 class="inline-middle bold mr5 ml5">{{ $t('select different flight') }}</h5>
                                </button>
                                <button type="button" class="btn btn_theme btn_md w-15 pl-0 pr-0  mr-5 ml-5"
                                    @click="this.confirm = 1;">
                                    <h5 class="inline-middle bold mr5 ml5">{{ $t('confirm my choice') }}</h5>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="need_payment == 0" class="col-lg-12 mt-4">
                    <div class="tour_details_right_sidebar_wrapper sticky-parent">
                        <div class="tour_detail_right_sidebar">
                            <div class="tour_details_right_boxed">
                                <div class="row text-center">
                                    <div class="col-md-12">
                                        <button type="button" class="btn btn_theme btn_md w-35  mt-4" @click="send">{{
                                            $t('Confirm the reservation and issue the ticket') }}</button>
                                        <div class="form-group mt-4">
                                            <input type="checkbox" id="confirm"
                                                class="form-check-input confirm-issue mt-0" v-model="confirm_issue">
                                            <label for="confirm" class="text-danger pl-2 pr-2 mb-0">
                                                {{ $t("confirm note") }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
var that;
import $ from 'jquery';
import jsonDataAr from '@/assets/file/ar-airports.json';
import jsonDataEn from '@/assets/file/en-airports.json';
import airlinesData from '@/assets/file/airlines.json';
import { toast } from 'vue3-toastify';
import LvLoader from 'lightvue/loaders';
export default {
    name: 'FlightDetailsArea',
    data: () => ({
        date: '',
        ret_date: '',
        departure: null,
        arrival: null,
        departure_flight: null,
        arrival_flight: null,
        airlines: [],
        passengers: [],
        flightOffers: [],
        agree: true,
        booking_id: '',
        searchData: [],
        getOutboundImg: '',
        getInboundImg: '',
        tripType: '',
        from: [],
        to: [],
        allowed_to_request_hotel_reservation: 0,
        allowed_to_request_extra_nights: 0,
        hotels: [],
        hotel_img: '',
        hotel_conditions: [],
        hotel_id: '',
        allowed_to_choose_hotel: 0,
        current_hotel: [],
        room_type: '',
        extra_room_type: '',
        arrival_time_to_riyadh: '',
        departure_time_for_riyadh: '',
        entry_hotel_date: '',
        exit_hotel_date: '',
        need_extra_end: false,
        need_assistance: '',
        count_extra_end: false,
        end_extra_date: '',
        essential_guest: 0,
        confirm: 0,
        confirm_issue: false,
        entry_date: null,
        total: 0,
        need_payment: 1,
        is_self_paying: 1,
        is_payment_loading: 0,
        payment_method: '',
        checkoutId: '',
        payment_redirect_url: '',
        markup: 0,
        payment_status: 0,
        sessionId: null,
    }),
    components: {
        LvLoader
    },
    methods: {
        get_price_with_markup(price) {
            price = parseFloat(price);
            var markup_percent = 0.1;
            this.markup = this.is_self_paying == 1 ? price * markup_percent : 0;
            var total = price + this.markup;
            return total;
        },
        formattedDate(date) {
            const parts = date.split('/');
            return new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
        },
        formattedDateRange(startDate, endDate) {
            const startParts = startDate.split('/');
            const endParts = endDate.split('/');
            const start = new Date(`${startParts[2]}-${startParts[1]}-${startParts[0]}`);
            const end = new Date(`${endParts[2]}-${endParts[1]}-${endParts[0]}`);
            return `${start.toLocaleDateString(undefined, { day: 'numeric' })} - ${end.toLocaleDateString('en-US', { day: 'numeric' })} ${this.$t(end.toLocaleDateString('en-US', { month: 'long' }))} ${end.toLocaleDateString('en-US', { year: 'numeric' })}`;
        },
        date_format(date) {
            var dateObject = new Date(date);
            const formattedDate = dateObject.toLocaleDateString('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            });
            return formattedDate;
        },
        add_days_to_date(date, days) {
            var dateObject = new Date(date);
            dateObject.setDate(dateObject.getDate() + parseInt(days));
            const formattedDate = dateObject.toLocaleDateString('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            });
            return formattedDate;
        },
        decrease_day_from_date(date) {
            const dateObject = new Date(date);
            dateObject.setDate(dateObject.getDate() - 1);
            const formattedDate = dateObject.toLocaleDateString('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            });
            return formattedDate;
        },
        need_an_extra_day_at_beginning(date, time, time_allowed_entry_before_booking) {
            var date1 = date.toLocaleDateString('en-US', {
                year: 'numeric'
            }) + '-' + date.toLocaleDateString('en-US', {
                month: '2-digit'
            }) + '-' + date.toLocaleDateString('en-US', {
                day: '2-digit'
            });
            const startTime = new Date(date);
            startTime.setHours(startTime.getHours() + time_allowed_entry_before_booking);
            const endTime = new Date(`${date1}T${time}`);
            const timeDifference = endTime - startTime;
            const hoursDifference = timeDifference / (1000 * 60 * 60);
            return hoursDifference > 0 ? true : false;
        },
        need_an_extra_day_at_end(date1, date2) {
            var date1 = new Date(date1), date2 = new Date(date2);
            const utcDate1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
            const utcDate2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
            const timeDiff = utcDate2 - utcDate1;
            const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
            return daysDiff > 0 ? true : false;
        },
        count_extra_day_at_end(date1, date2) {
            var date1 = new Date(date1), date2 = new Date(date2);
            const utcDate1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
            const utcDate2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
            const timeDiff = utcDate2 - utcDate1;
            const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
            return daysDiff;
        },
        get_time_formated(date) {
            var dateObject = new Date(date);
            var hours = dateObject.getHours();
            var minutes = dateObject.getMinutes();
            const formattedTime = `${hours.toLocaleString('en-US', { minimumIntegerDigits: 2 })}:${minutes.toLocaleString('en-US', { minimumIntegerDigits: 2 })}`;
            return formattedTime;
        },
        changeEntry(date) {
            const dateStr1 = date;
            const dateStr2 = this.entry_date;
            const date1 = new Date(dateStr1);
            const date2 = new Date(dateStr2);
            if (date1 < date2 || date1 > date2) {
                setTimeout(() => {
                    $(document).find('.dp__action_select').click();
                }, 50);
            }
            if (this.entry_date) {
                this.arrival_time_to_riyadh = this.entry_date;
                this.entry_hotel_date = this.date_format(this.arrival_time_to_riyadh);
                this.exit_hotel_date = this.add_days_to_date(this.entry_hotel_date, this.hotel_conditions.free_duration);
                if (this.exit_hotel_date > this.departure_time_for_riyadh) {
                    this.exit_hotel_date = new Date(this.departure_time_for_riyadh).toLocaleDateString('fr-CA', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    });
                }
                this.need_extra_end = this.need_an_extra_day_at_end(this.exit_hotel_date, this.departure_time_for_riyadh);
                if (this.need_extra_end) {
                    this.count_extra_end = this.count_extra_day_at_end(this.exit_hotel_date, this.departure_time_for_riyadh);
                    this.end_extra_date = this.add_days_to_date(this.exit_hotel_date, this.count_extra_end);
                }
            }
        },
        changeHotel() {
            this.need_assistance = 0;
            this.confirm = 0;
            this.current_hotel = this.hotels.filter(item => {
                return item.id == this.hotel_id;
            })[0];
            this.hotel_img = `${this.$saudicupAppUrl}/storage` + this.current_hotel.images[0].image;
            var time_allowed_entry_before_booking = this.current_hotel.time_allowed_entry_before_booking;
            var need_extra = this.need_an_extra_day_at_beginning(new Date(this.arrival_time_to_riyadh), this.get_time_formated(this.current_hotel.entry_time), time_allowed_entry_before_booking);
            var free_duration = this.hotel_conditions.free_duration;
            if (need_extra) {
                this.entry_hotel_date = this.decrease_day_from_date(this.arrival_time_to_riyadh);
                free_duration = parseInt(free_duration) + 1;
            } else {
                this.entry_hotel_date = this.date_format(this.arrival_time_to_riyadh);
            }
            this.exit_hotel_date = this.add_days_to_date(this.entry_hotel_date, free_duration);
            if (this.exit_hotel_date > this.departure_time_for_riyadh) {
                this.exit_hotel_date = new Date(this.departure_time_for_riyadh).toLocaleDateString('fr-CA', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                });
            }
            this.need_extra_end = this.need_an_extra_day_at_end(this.exit_hotel_date, this.departure_time_for_riyadh);
            if (this.tripType == 'oneway') {
                this.exit_hotel_date = null;
                this.entry_hotel_date = null;
                this.need_extra_end = false;
                this.entry_date = null;
            }
            if (this.need_extra_end) {
                this.count_extra_end = this.count_extra_day_at_end(this.exit_hotel_date, this.departure_time_for_riyadh);
                this.end_extra_date = this.add_days_to_date(this.exit_hotel_date, this.count_extra_end);
            }
        },
        convertDuration(duration) {
            const regex = /PT(\d+)H(\d+)M/;
            const regex2 = /PT(\d+)H/;
            const match = regex.exec(duration);
            const match2 = regex2.exec(duration);
            if (match) {
                const hours = match[1].padStart(2, '0');
                const minutes = match[2].padStart(2, '0');
                if (that.$i18n.locale === 'en') {
                    return hours + 'h ' + minutes + 'm';
                }
                return hours + 'س ' + minutes + 'د';
            } else if (match2) {
                const hours = match2[1].padStart(2, '0');
                const minutes = '00';
                if (that.$i18n.locale === 'en') {
                    return hours + 'h ' + minutes + 'm';
                }
                return hours + 'س ' + minutes + 'د';
            }
            return '';
        },
        getLocationByCode(code) {
            var jsonData = jsonDataAr;
            if (that.$i18n.locale === 'en') {
                jsonData = jsonDataEn;
            }
            var info = jsonData.filter(item => {
                return item.code.includes(code);
            });
            return info[0];
        },
        convertTimeToISO8601(timeString) {
            timeString = timeString.replace(/[^\d:]/g, '');
            const [hours, minutes] = timeString.split(':').map(Number);
            if (isNaN(hours) || isNaN(minutes)) {
                throw new Error('Invalid time format');
            }
            const iso8601Duration = `PT${hours}H${minutes}M`;
            return iso8601Duration;
        },
        send() {
            if (this.allowed_to_request_hotel_reservation == 1 && this.essential_guest == 1 && this.is_self_paying == 0) {
                if (this.tripType == 'oneway' && this.entry_date == null) {
                    toast.error(this.$t('Hotel check-in date is required'), {
                        autoClose: 3500,
                        position: toast.POSITION.TOP_CENTER,
                        closeButton: false
                    });
                    return;
                }
                if (this.room_type == '') {
                    toast.error(this.$t('Room type required'), {
                        autoClose: 3500,
                        position: toast.POSITION.TOP_CENTER,
                        closeButton: false
                    });
                    return;
                }
                if (this.need_extra_end) {
                    if (this.confirm == 0) {
                        toast.error(this.$t('Please confirm your choice'), {
                            autoClose: 3500,
                            position: toast.POSITION.TOP_CENTER,
                            closeButton: false
                        });
                        return;
                    }
                    if (this.allowed_to_request_extra_nights == 1) {
                        if (this.need_assistance == '') {
                            toast.error(this.$t('All fields are required'), {
                                autoClose: 3500,
                                position: toast.POSITION.TOP_CENTER,
                                closeButton: false
                            });
                            return;
                        }
                        if (this.need_assistance == 1) {
                            if (this.extra_room_type == '') {
                                toast.error(this.$t('Room type required'), {
                                    autoClose: 3500,
                                    position: toast.POSITION.TOP_CENTER,
                                    closeButton: false
                                });
                                return;
                            }
                        }
                    }
                }
            }
            if (!this.confirm_issue) {
                toast.error(this.$t('Please agree to the terms'), {
                    autoClose: 3500,
                    position: toast.POSITION.TOP_CENTER,
                    closeButton: false
                });
                return;
            }
            toast.error(this.$t('Booking confirmation is not available in the demo environment.'), {
                autoClose: 3500,
                position: toast.POSITION.TOP_CENTER,
                closeButton: false
            });
        }
    },
    mounted() {
        that = this;
        $('.pre-loader').show();
        this.airlines = airlinesData;
        this.sessionId = this.$route.query.sessionId;
        this.allowed_to_request_hotel_reservation = localStorage.getItem("allowed_to_request_hotel_reservation");
        this.is_self_paying = localStorage.getItem("is_self_paying");
        this.need_payment = localStorage.getItem("is_self_paying");
        this.tripType = localStorage.getItem("tripType");
        this.hotel_conditions = JSON.parse(localStorage.getItem("hotel_conditions"));
        this.hotel_id = localStorage.getItem("hotel_id");
        this.allowed_to_choose_hotel = localStorage.getItem("allowed_to_choose_hotel");
        this.allowed_to_request_extra_nights = localStorage.getItem("allowed_to_request_extra_nights");
        this.essential_guest = localStorage.getItem("essential_guest");
        this.searchData = JSON.parse(localStorage.getItem("searchData"));
        if (!this.searchData) {
            window.location = '/erorr';
        }

        var url = `${this.$appUrl}/api/almosafer/make_auth`;
        this.axios.get(url).then((response) => {
            const data = response.data;
            var accessToken = data.accessToken;
            var url = `${this.$appUrl}/api/almosafer/session/${this.sessionId}?lang=${localStorage.getItem("locale")}`;
            this.axios.get(url, {
                headers: {
                    "Authorization": "Bearer " + accessToken,
                    "Accept": "*/*",
                    "Content-Type": "application/json",
                }
            }).then((response) => {
                this.departure_flight = response.data.request.flights.segments[0];
                this.arrival_flight = response.data.request.flights.segments[1];
                if (this.tripType == 'roundtrip') {
                    var departure_flight_count = this.departure_flight.legs.length,
                        arrival_flight_count = this.arrival_flight.legs.length;
                    var departure = jsonDataEn.filter(item => {
                        return item.code.includes(this.searchData.from);
                    });
                    var arrival = jsonDataEn.filter(item => {
                        return item.code.includes(this.searchData.to);
                    });
                    var departureLat = departure[0]['lat'],
                        departureLng = departure[0]['lng'];
                    var arrivalLat = arrival[0]['lat'],
                        arrivalLng = arrival[0]['lng'];

                    var aa = ``, bb = ``;
                    for (let index = 0; index < this.arrival_flight.legs.length; index++) {
                        var depa = jsonDataEn.filter(item => {
                            return item.code.includes(this.arrival_flight.legs[index].originCode);
                        });
                        var depaLat = depa[0]['lat'],
                            depaLng = depa[0]['lng'];
                        aa += ` &markers=icon:https://dsx9kbtamfpyb.cloudfront.net/desktop-web-fav4/view/assets/img/dept-out.png
                  |color:red%7Clabel:K%7C
                  ${depaLat},${depaLng}`;
                        bb += `|${depaLat},${depaLng}`;
                        var arr = jsonDataEn.filter(item => {
                            return item.code.includes(this.arrival_flight.legs[index].destinationCode);
                        });
                        var arrLat = arr[0]['lat'],
                            arrLng = arr[0]['lng'];
                        aa += ` &markers=icon:https://dsx9kbtamfpyb.cloudfront.net/desktop-web-fav4/view/assets/img/dept-out.png
                  |color:red%7Clabel:K%7C
                  ${arrLat},${arrLng}`;
                        bb += `|${arrLat},${arrLng}`;

                    }
                    this.getInboundImg = `https://maps.google.com/maps/api/staticmap?
                  center=${((parseFloat(departureLat) + parseFloat(arrivalLat)) / 2)},${((parseFloat(departureLng) + parseFloat(arrivalLng)) / 2)}
                  &type=terrain
                  &size=550x${arrival_flight_count == 1 ? 350 : arrival_flight_count * 200}
                  &key=AIzaSyCU_-f44KpO2Mhupb-qWLQY-bp0_tQaUzs
                  ${aa}
                  &path=color:0x7366CC
                  |weight:4
                  |geodesic:true
                  ${bb}
                  &sensor=false
                  &region=ar`;
                    var departure = jsonDataEn.filter(item => {
                        return item.code.includes(this.searchData.to);
                    });
                    var arrival = jsonDataEn.filter(item => {
                        return item.code.includes(this.searchData.from);
                    });
                    var departureLat = departure[0]['lat'],
                        departureLng = departure[0]['lng'];
                    var arrivalLat = arrival[0]['lat'],
                        arrivalLng = arrival[0]['lng'];
                    var aa = ``, bb = ``;
                    for (let index = 0; index < this.departure_flight.legs.length; index++) {
                        var depa = jsonDataEn.filter(item => {
                            return item.code.includes(this.departure_flight.legs[index].originCode);
                        });
                        var depaLat = depa[0]['lat'],
                            depaLng = depa[0]['lng'];
                        aa += ` &markers=icon:https://dsx9kbtamfpyb.cloudfront.net/desktop-web-fav4/view/assets/img/dept-out.png
                  |color:red%7Clabel:K%7C
                  ${depaLat},${depaLng}`;
                        bb += `|${depaLat},${depaLng}`;
                        var arr = jsonDataEn.filter(item => {
                            return item.code.includes(this.departure_flight.legs[index].destinationCode);
                        });
                        var arrLat = arr[0]['lat'],
                            arrLng = arr[0]['lng'];
                        aa += ` &markers=icon:https://dsx9kbtamfpyb.cloudfront.net/desktop-web-fav4/view/assets/img/dept-out.png
                  |color:red%7Clabel:K%7C
                  ${arrLat},${arrLng}`;
                        bb += `|${arrLat},${arrLng}`;

                    }
                    this.getOutboundImg = `https://maps.google.com/maps/api/staticmap?
                  center=${((parseFloat(departureLat) + parseFloat(arrivalLat)) / 2)},${((parseFloat(departureLng) + parseFloat(arrivalLng)) / 2)}
                  &type=terrain
                  &size=550x${departure_flight_count == 1 ? 350 : departure_flight_count * 200}
                  &key=AIzaSyCU_-f44KpO2Mhupb-qWLQY-bp0_tQaUzs
                  ${aa}
                  &path=color:0x3366CC
                  |weight:4
                  |geodesic:true
                  ${bb}
                  &sensor=false
                  &region=ar`;
                    var jsonData = jsonDataAr;
                    if (this.$i18n.locale === 'en') {
                        jsonData = jsonDataEn;
                    }

                    this.departure = jsonData.filter(item => {
                        return item.code.includes(this.searchData.from);
                    })[0].city;
                    this.arrival = jsonData.filter(item => {
                        return item.code.includes(this.searchData.to);
                    })[0].city;
                    var type = 0;
                    var array = ``;
                    for (let index = 0; index < this.departure_flight.legs.length; index++) {
                        var departure = jsonData.filter(item => {
                            return item.code.includes(this.departure_flight.legs[index].originCode);
                        });
                        var arrival = jsonData.filter(item => {
                            return item.code.includes(this.departure_flight.legs[index].destinationCode);
                        });
                        if (this.departure_flight.legs.length > 1 && index > 0) {
                            const dateTime1 = new Date(this.departure_flight.legs[index].departureDate + 'T' + this.departure_flight.legs[index].departureTime + ':00');
                            const dateTime2 = new Date(this.departure_flight.legs[index - 1].arrivalDate + 'T' + this.departure_flight.legs[index - 1].arrivalTime + ':00');
                            const differenceInMs = Math.abs(dateTime2 - dateTime1);
                            const hours = Math.floor(differenceInMs / (1000 * 60 * 60));
                            const minutes = Math.floor((differenceInMs % (1000 * 60 * 60)) / (1000 * 60));
                            if (that.$i18n.locale === 'ar') {
                                array += `<div class="clearfix layover"><span> ${hours}س ${minutes}د ${that.$t('Stop')}</span></div>`;
                            } else {
                                array += `<div class="clearfix layover"><span> stop ${hours}h ${minutes}m</span></div>`;
                            }
                        }
                        array += `
                            <div class="row no-margin p20 fl-itinerary">
                                <div class="col-md-4 mt20 text-center">
                                  <div>
                                    <h3 class="prime-color">${this.departure_flight.legs[index].originCode}</h3>
                                    <h4 class="no-margin new-light">${new
                                Date(this.departure_flight.legs[index].departureDate + 'T' + this.departure_flight.legs[index].departureTime + ':00').toLocaleTimeString('fr-FR', {
                                    hour:
                                        '2-digit', minute: '2-digit'
                                })}</h4> <span>${new Date(this.departure_flight.legs[index].departureDate + 'T' + this.departure_flight.legs[index].departureTime + ':00').toLocaleDateString('en-US',
                                    { day: 'numeric' })} ${that.$t(new Date(this.departure_flight.legs[index].departureDate + 'T' + this.departure_flight.legs[index].departureTime + ':00').toLocaleDateString('en-US', {
                                        month:
                                            'long'
                                    }))} ${new
                                        Date(this.departure_flight.legs[index].departureDate + 'T' + this.departure_flight.legs[index].departureTime + ':00').getFullYear()}</span>
                                  </div>
                                  <div>
                                    <div class="ellipsis no-wrap">${departure[0].name}</div>
                                    <div class="ellipsis no-wrap">${departure[0].city}, ${departure[0].country}</div>
                                  </div>
                                </div>
                                <div class="col-md-4 text-center">
                                  <div class="mb5">
                                    <div class="fl-img-con">
                                      <div class="flight-image i-${this.departure_flight.legs[index].airlineCode.toString().toLowerCase()}"></div>
                                    </div>
                                    <p class="no-margin font-12"> <span>${this.airlines[this.departure_flight.legs[index].airlineCode][this.$i18n.locale]}</span> <span class="clearfix">${this.departure_flight.legs[index].airlineCode}${this.departure_flight.legs[index].flightNumber}</span>
                                    </p> <span>${that.$t(this.departure_flight.legs[index].cabinClass)}</span>
                                    <div class="clearfix">
                                      <div class="oneway"></div>
                                    </div>
                                  </div>
                                  <div>
                                    <div></div>
                                    <div>${this.convertDuration(this.convertTimeToISO8601(this.departure_flight.legs[index].duration))}</div>
                                  </div>
                                </div>
                                <div class="col-md-4 mt20 text-center">
                                  <div>
                                    <h3 class="prime-color">${this.departure_flight.legs[index].destinationCode}</h3>
                                    <h4 class="no-margin new-light">${new
                                Date(this.departure_flight.legs[index].arrivalDate + 'T' + this.departure_flight.legs[index].arrivalTime + ':00').toLocaleTimeString('fr-FR', {
                                    hour:
                                        '2-digit', minute: '2-digit'
                                })}</h4> 
                                      <span>${new Date(this.departure_flight.legs[index].arrivalDate + 'T' + this.departure_flight.legs[index].arrivalTime + ':00').toLocaleDateString('en-US',
                                    { day: 'numeric' })} ${that.$t(new Date(this.departure_flight.legs[index].arrivalDate + 'T' + this.departure_flight.legs[index].arrivalTime + ':00').toLocaleDateString('en-US', {
                                        month:
                                            'long'
                                    }))} ${new
                                        Date(this.departure_flight.legs[index].arrivalDate + 'T' + this.departure_flight.legs[index].arrivalTime + ':00').getFullYear()}</span>
                                  </div>
                                  <div>
                                    <div class="ellipsis no-wrap">${arrival[0].name}</div>
                                    <div class="ellipsis no-wrap">${arrival[0].city}, ${arrival[0].country}</div>
                                  </div>
                                </div>
                              </div>
                             `;
                        if (index == this.departure_flight.legs.length - 1) {
                            this.arrival_time_to_riyadh = this.departure_flight.legs[index].arrivalDate + 'T' + this.departure_flight.legs[index].arrivalTime + ':00';
                        }
                    }
                    $('.departure-itinerary .itinerary').html(array);
                    type = 1;
                    array = ``;
                    for (let index = 0; index < this.arrival_flight.legs.length; index++) {
                        departure = jsonData.filter(item => {
                            return item.code.includes(this.arrival_flight.legs[index].originCode);
                        });
                        arrival = jsonData.filter(item => {
                            return item.code.includes(this.arrival_flight.legs[index].destinationCode);
                        });
                        if (this.arrival_flight.legs.length > 1 && index > 0) {
                            const dateTime1 = new Date(this.arrival_flight.legs[index].departureDate + 'T' + this.arrival_flight.legs[index].departureTime + ':00');
                            const dateTime2 = new Date(this.arrival_flight.legs[index - 1].arrivalDate + 'T' + this.arrival_flight.legs[index - 1].arrivalTime + ':00');
                            const differenceInMs = Math.abs(dateTime2 - dateTime1);
                            const hours = Math.floor(differenceInMs / (1000 * 60 * 60));
                            const minutes = Math.floor((differenceInMs % (1000 * 60 * 60)) / (1000 * 60));
                            if (that.$i18n.locale === 'ar') {
                                array += `<div class="clearfix layover"><span> ${hours}س ${minutes}د ${that.$t('Stop')}</span></div>`;
                            } else {
                                array += `<div class="clearfix layover"><span> stop ${hours}h ${minutes}m</span></div>`;
                            }
                        }
                        array += `
                             <div class="row no-margin p20 fl-itinerary">
                                <div class="col-md-4 mt20 text-center">
                                  <div>
                                    <h3 class="prime-color">${this.arrival_flight.legs[index].originCode}</h3>
                                    <h4 class="no-margin new-light">${new
                                Date(this.arrival_flight.legs[index].departureDate + 'T' + this.arrival_flight.legs[index].departureTime + ':00').toLocaleTimeString('fr-FR', {
                                    hour:
                                        '2-digit', minute: '2-digit'
                                })}</h4> <span>${new Date(this.arrival_flight.legs[index].departureDate + 'T' + this.arrival_flight.legs[index].departureTime + ':00').toLocaleDateString('en-US',
                                    { day: 'numeric' })} ${that.$t(new Date(this.arrival_flight.legs[index].departureDate + 'T' + this.arrival_flight.legs[index].departureTime + ':00').toLocaleDateString('en-US', {
                                        month:
                                            'long'
                                    }))} ${new
                                        Date(this.arrival_flight.legs[index].departureDate + 'T' + this.arrival_flight.legs[index].departureTime + ':00').getFullYear()}</span>
                                  </div>
                                  <div>
                                    <div class="ellipsis no-wrap">${departure[0].name}</div>
                                    <div class="ellipsis no-wrap">${departure[0].city}, ${departure[0].country}</div>
                                  </div>
                                </div>
                                <div class="col-md-4 text-center">
                                  <div class="mb5">
                                    <div class="fl-img-con">
                                      <div class="flight-image i-${this.arrival_flight.legs[index].airlineCode.toString().toLowerCase()}"></div>
                                    </div>
                                    <p class="no-margin font-12"> <span>${this.airlines[this.arrival_flight.legs[index].airlineCode][this.$i18n.locale]}</span> <span class="clearfix">${this.arrival_flight.legs[index].airlineCode}${this.arrival_flight.legs[index].flightNumber}</span>
                                    </p> <span>${that.$t(this.arrival_flight.legs[index].cabinClass)}</span>
                                    <div class="clearfix">
                                      <div class="oneway"></div>
                                    </div>
                                  </div>
                                  <div>
                                    <div></div>
                                    <div>${that.convertDuration(this.convertTimeToISO8601(this.arrival_flight.legs[index].duration))}</div>
                                  </div>
                                </div>
                                <div class="col-md-4 mt20 text-center">
                                  <div>
                                    <h3 class="prime-color">${this.arrival_flight.legs[index].destinationCode}</h3>
                                    <h4 class="no-margin new-light">${new
                                Date(this.arrival_flight.legs[index].arrivalDate + 'T' + this.arrival_flight.legs[index].arrivalTime + ':00').toLocaleTimeString('fr-FR', {
                                    hour:
                                        '2-digit', minute: '2-digit'
                                })}</h4> 
                    <span>${new Date(this.arrival_flight.legs[index].arrivalDate + 'T' + this.arrival_flight.legs[index].arrivalTime + ':00').toLocaleDateString('en-US',
                                    { day: 'numeric' })} ${that.$t(new Date(this.arrival_flight.legs[index].arrivalDate + 'T' + this.arrival_flight.legs[index].arrivalTime + ':00').toLocaleDateString('en-US', {
                                        month:
                                            'long'
                                    }))} ${new
                                        Date(this.arrival_flight.legs[index].arrivalDate + 'T' + this.arrival_flight.legs[index].arrivalTime + ':00').getFullYear()}</span>
                                  </div>
                                  <div>
                                    <div class="ellipsis no-wrap">${arrival[0].name}</div>
                                    <div class="ellipsis no-wrap">${arrival[0].city}, ${arrival[0].country}</div>
                                  </div>
                                </div>
                              </div>
                             `;
                        if (index == 0) {
                            this.departure_time_for_riyadh = this.arrival_flight.legs[index].departureDate + 'T' + this.arrival_flight.legs[index].departureTime + ':00';
                        }
                    }
                    $('.arrival-itinerary .itinerary').html(array);
                }
                if (this.allowed_to_request_hotel_reservation == 1) {
                    var url = `${this.$saudicupAppUrl}/api/guest/hotels`;
                    this.axios.get(url, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('user_token')}`
                        }
                    }).then((response) => {
                        var data = response.data.data;
                        this.hotels = data;
                        this.current_hotel = this.hotels.filter(item => {
                            return item.id == this.hotel_id;
                        })[0];
                        this.hotel_img = `${this.$saudicupAppUrl}/storage` + this.current_hotel.images[0].image;
                        var time_allowed_entry_before_booking = this.current_hotel.time_allowed_entry_before_booking;

                        var need_extra = this.need_an_extra_day_at_beginning(new Date(this.arrival_time_to_riyadh), this.get_time_formated(this.current_hotel.entry_time), time_allowed_entry_before_booking);
                        var free_duration = this.hotel_conditions.free_duration;
                        if (need_extra) {
                            this.entry_hotel_date = this.decrease_day_from_date(this.arrival_time_to_riyadh);
                            free_duration = parseInt(free_duration) + 1;
                        } else {
                            this.entry_hotel_date = this.date_format(this.arrival_time_to_riyadh);
                        }
                        this.exit_hotel_date = this.add_days_to_date(this.entry_hotel_date, free_duration);
                        if (this.exit_hotel_date > this.departure_time_for_riyadh) {
                            this.exit_hotel_date = new Date(this.departure_time_for_riyadh).toLocaleDateString('fr-CA', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                            });
                        }
                        this.need_extra_end = this.need_an_extra_day_at_end(this.exit_hotel_date, this.departure_time_for_riyadh);
                        if (this.tripType == 'oneway') {
                            this.exit_hotel_date = null;
                            this.entry_hotel_date = null;
                            this.need_extra_end = false;
                        }
                        if (this.need_extra_end) {
                            this.count_extra_end = this.count_extra_day_at_end(this.exit_hotel_date, this.departure_time_for_riyadh);
                            this.end_extra_date = this.add_days_to_date(this.exit_hotel_date, this.count_extra_end);
                        }
                        $('.pre-loader').hide();
                    });
                } else {
                    $('.pre-loader').hide();
                }
            });
        });
    }
}
</script>
<style lang="scss" scoped>
.color-label {
    color: #09683e!important;
    font-weight: 700!important;
    .note{
        color: rgb(192, 160, 52);
    }
}
.form-control{
        padding: 5px 22px;
        font-size: 14px;
        height: 37px;
        border-radius: 0.25rem;
}
label{
    font-size: 14px;
    margin-bottom: 8px;
    color: #0b6938;
    font-weight: 600;
}
.form-group{
    margin-bottom: 1rem;
}
.section_padding {
    padding: 60px 0;
}
.page-loader-parent:has(.page-loader) {
    position: relative;
    min-height: 280px;
}

.mb20 {
    margin-bottom: 20px !important;
}

.pass-con {
    background-color: #0a0f57;
    color: #fff;
    padding: 15px;
    border-radius: 3px;
}

.row.align>.col-middle {
    float: none;
    display: inline-block;
    vertical-align: middle;
    clear: both;
    white-space: initial;
}

.rtl .pass-count {
    margin-right: 20px;
    margin-left: 0 !important;
}

.rtl .pass-count span {
    font-size: 14px;
}

.pass-con .btn,
.pass-con .btn:active,
.pass-con .btn:after,
.pass-con .btn:focus,
.pass-con .btn:hover {
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
}

.mb30 {
    margin-bottom: 30px !important;
}

.row.align {
    padding: 0;
}

.sticky-parent {
    transition: 1s;
    bottom: 50px;
    position: sticky !important;
    position: -webkit-sticky !important;
    z-index: 100;
    top: 145px;
}

.pass-ticket-con {
    background: #fff;
    padding: 20px;
    border-radius: 3px;
    transition: .8s;
}


.oneway,
.oneway-2x,
.oneway-left,
.roundtrip,
.roundtrip-2x {
    background-repeat: no-repeat;
    display: inline-block;
    width: 50px;
    height: 26px;
    background-position: center;
    vertical-align: middle;
}

.flip {
    transform: scaleX(-1);
}

.fl-30-ico.calendar-pink-ico {
    background-position: -180px -300px;
}

.fl-30-ico {
    background-image: url('~@/assets/img/icons/30x30.png');
    width: 30px;
    height: 30px;
    display: inline-block;
    vertical-align: middle;
    background-repeat: no-repeat;
}

.fl-30-ico.passengers {
    background-position: 0 -90px;
}

.ticket-hole:after {
    right: -30px;
}

.ticket-hole:after,
.ticket-hole:before {
    content: '';
    width: 20px;
    height: 20px;
    background-color: #e6f0ff;
    position: absolute;
    top: -12px;
    border-radius: 50%;
}

.ticket-hole:before {
    left: -30px;
}

.ticket-hole>hr {
    margin-top: 15px;
    margin-bottom: 15px;
    border-top: 1px dashed #a3a3a3;
}

.dash-line {
    border-top: 1px dashed #a3a3a3;
}

.ticket-hole {
    position: relative;
}

.mb0 {
    margin-bottom: 0px;
}

.passenger-info {
    padding-top: 145px;
}

.pass-icon {
    background-image: url('~@/assets/img/icons/addpass.png');
    width: 30px;
    height: 20px;
    margin: 0 5px;
}

.h4,
h4 {
    font-size: 18px;
}

.pass-icon {
    background-position: center;
    display: inline-block;
    background-repeat: no-repeat;
    vertical-align: middle;
}



.frm-js-lbl label {
    right: 0px;
    left: inherit;
}

.frm-js-lbl {
    margin: 0;
    padding: 0;
    border: 0;
    position: relative;
    height: 36px;
}

.pass-spec-container {
    background: #fff;
    max-height: 87px;
    cursor: pointer;
    position: relative;
    padding: 15px;
    border-radius: 3px;
}

.fl-pass-info .pass-image,
.pass-spec-container .pass-image {
    background-color: #2c6dfb;
    color: #fff;
    font-size: 12px;
    border: 0;
    border-radius: 50%;
    margin: 0 15px;
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    text-align: center;
    padding: 1;
    width: 40px;
    height: 40px;
    line-height: 35px;
    padding-top: 3px;
}

.mb20 {
    margin-bottom: 20px !important;
}

.pass-spec-container .pass-details .left {
    display: inline-block;
    vertical-align: middle;
}

.pass-spec-container .pass-details {
    display: inline-block;
    width: calc(100% - 35px);
    cursor: pointer;
    white-space: nowrap;
}

.btn-fa {
    width: 25px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
}

.edit-icon {
    background-image: url('~@/assets/img/icons/edit.png');
}

.delete-icon,
.edit-icon {
    width: 23px;
    height: 23px;
}

.edit-icon {
    background-position: center;
    display: inline-block;
    background-repeat: no-repeat;
    vertical-align: middle;
}

.pass-spec-container .pass-details .right {
    text-align: right;
}

.pass-spec-container .pass-details .right {
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: calc(100% - 70px);
    direction: ltr;
    text-align: left;
}

.icheckbox_square,
.icheckbox_square-blue,
.icheckbox_square-green,
.icheckbox_square-grey,
.icheckbox_square-orange,
.icheckbox_square-pink,
.icheckbox_square-purple,
.icheckbox_square-red,
.icheckbox_square-yellow,
.iradio_square,
.iradio_square-blue,
.iradio_square-green,
.iradio_square-grey,
.iradio_square-orange,
.iradio_square-pink,
.iradio_square-purple,
.iradio_square-red,
.iradio_square-yellow {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 22px;
    height: 22px;
    border: none;
}

.no-margin {
    margin: 0;
}

.checkbox-container {
    display: inline-block;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
}

.checkbox-container input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: -12px;
    left: 0;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    border: 1px solid #ccc;
}

.checkbox-container input[type="radio"]:checked~.checkmark {
    background: url('~@/assets/img/green.png') no-repeat;
    background-position: -49px -1px;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-container input[type="radio"]:checked~.checkmark:after {
    display: block;
}

.checkbox-container .checkmark:after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 22px;
    height: 22px;
    border-radius: 50%;
}

.fl-30-ico.info-ico {
    background-position: -120px -270px;
}

.price-text .total {
    font-size: 24px;
}

.price-text .currency {
    font-size: 18px;
}

.new-bold-light {
    font-weight: 600;
    font-style: normal;
    color: #0b6938;
}

.fl-30-ico {
    background-image: url('~@/assets/img/icons/30x30.png');
    width: 30px;
    height: 30px;
    display: inline-block;
    vertical-align: middle;
    background-repeat: no-repeat;
}

.itinerary-content {
    background-color: #fff;
    border-radius: 10px;
    padding-bottom: 5px;
}

#booked-inbound img,
#booked-outbound img,
#inbound-map img,
#outbound-map img {
    width: 100%;
}

.p10 {
    padding: 10px;
}

.fl-back,
.trip-out,
.trip-in,
.chev-arrow.right,
.chev-arrow.left,
.fl-trip .outbound,
.fl-trip .inbound {
    transform: scaleX(-1);
}

.trip-in,
.trip-in.white {
    background-position: -50px 0;
}

.trip-out,
.trip-out.white {
    background-position: 0 0;
}

.trip-in,
.trip-out {
    background: url('~@/assets/img/svg/trip.svg') no-repeat;
    display: inline-block;
    vertical-align: middle;
    width: 50px;
    height: 32px;
    background-size: cover;
}

.psngr-icon {
    background-image: url('~@/assets/img/icons/passengers.png');
    width: 26px;
    height: 25px;
}

.fl-trip {
    display: inline-block;
    vertical-align: middle;
}

.fl-trip {
    margin-right: inherit;
    margin-left: 15px;
}

.ml15 {
    margin-left: 15px !important;
}

.fl-pass-info .seat-img {
    width: 35px;
}

.fl-pass-info {
    padding: 20px 15px;
}

.mr15 {
    margin-right: 15px !important;
}

.fl-30-ico.fl-coupon {
    background-position: -210px 0;
}

.fl-30-ico {
    background-image: url('~@/assets/img/icons/30x30.png');
    width: 30px;
    height: 30px;
    display: inline-block;
    vertical-align: middle;
    background-repeat: no-repeat;
}

.fl-30-ico.fl-cards {
    background-position: -60px -210px;
}

.border-top-1 {
    border-top: 1px solid #f0eeee;
}


.checkbox-container {
    display: inline-block;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
}

.checkbox-container input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: -10px;
    left: 0;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    border: 1px solid #ccc;
}

.checkbox-container input[type="radio"]:checked~.checkmark {
    background: url('~@/assets/img/green.png') no-repeat;
    background-position: -49px -1px;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-container input[type="radio"]:checked~.checkmark:after {
    display: block;
}

.checkbox-container .checkmark:after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 22px;
    height: 22px;
    border-radius: 50%;
}
</style>
<style scoped>
.search-bar-wrapper {
    background-color: #fff;
    background-color: #0a0f57;
    width: 100%;
    margin-bottom: 20px;
    transition: .3s;
    color: #fff;
    z-index: 998;
    width: 100%;
    position: fixed;
    top: 60px;
    max-height: 147px;
}

.fl-back {
    background-image: url('~@/assets/img/icons/back-left.png');
    height: 24px;
    width: 27px;
}

.rtl .fl-back {
    transform: scaleX(-1);
}

.lh-50 {
    line-height: 50px;
}

.pl0 {
    padding-left: 0 !important;
}

.pr0 {
    padding-right: 0 !important;
}

.btn-search-outline {
    background: 0 0;
    border: 1px solid #e6f0ff;
    color: #e6f0ff;
    outline: 0;
    box-shadow: none;
}

.p10 {
    padding: 10px;
}

.bold {
    font-weight: 500;
    font-style: normal;
}

.padding-10-15 {
    padding: 10px 15px;
}

.bk-nav-ul,
.dh-nav-ul {
    list-style: none;
    height: 100%;
    padding: 0;
    min-height: 55px;
    padding-top: 10px;
}

.check-icon.check-white {
    background-position: -16px;
}

.check-icon {
    background-image: url('~@/assets/img/svg/check-16x12.svg');
    height: 12px;
    width: 16px;
    display: inline-block;
    margin: 0 5px;
}

.pull-left {
    float: right !important;
}

.bk-nav-ul>li,
.dh-nav-ul>li {
    display: inline-block;
    height: 100%;
    white-space: nowrap;
}

.corporate-logo {
    display: inline-block;
    background-position: center;
    background-repeat: no-repeat;
    height: 55px;
    object-fit: contain;
    width: 100px;
    margin-right: -20px;
}

.bk-nav-ul>li>a,
.bk-nav-ul>li>div,
.dh-nav-ul>li>a,
.dh-nav-ul>li>div {
    color: rgba(255, 255, 255, .5);
    padding: 10px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

.bk-nav-ul>li>a.active {
    color: #fff;
}

.height-100p {
    height: 100%;
}

.booking-nav {
    background-color: #fff;
    background-color: #0a0f57;
    width: 100%;
    margin-bottom: 20px;
    transition: .3s;
    color: #fff;
    z-index: 998;
    width: 100%;
    position: fixed;
    top: 60px;
    height: 65px;
}

.fl-back {
    background-position: center;
    display: inline-block;
    background-repeat: no-repeat;
    vertical-align: middle;
    cursor: pointer;
}

.fl-30-ico.employee-notes {
    background-position: -60px -210px;
}

.division {
    border-bottom: 1px solid #f1f1f1;
    padding: 0;
}

.pull-spinner {
    display: block;
    height: 25px;
    width: 25px;
    margin: 0 auto;
    position: relative;
    left: -4px;
    animation: rotation .6s infinite linear;
    border-left: 4px solid rgba(202, 202, 202, .15);
    border-right: 4px solid rgba(202, 202, 202, .15);
    border-bottom: 4px solid rgba(202, 202, 202, .15);
    border-top: 4px solid rgba(202, 202, 202, .9);
    border-radius: 100%;
}

.fl-ico.negative {
    background-position: -115px -45px;
}

.purposeOfTravel {
    color: #939393;
    margin-top: 24px;
}

.card-type.mada {
    background-position: -111px 0;
    background-image: url('~@/assets/img/svg/mada.svg');
    background-size: cover;
    width: 42px;
    background-position: center;
}

.card-type {
    display: inline-block;
    vertical-align: middle;
    width: 37px;
    height: 28px;
    background-repeat: no-repeat;
    background-image: url('~@/assets/img/icons/37x25.png');
    margin: 0px 5px;
}

.card-type.visa {
    background-position: -37px 0;
}

.card-type.mastercard {
    background-position: 0 0;
}

.card-type.amex {
    background-position: -74px 0;
}

.footer-card {
    position: relative;
}

.card-type.sadad {
    background-position: -74px -28px;
    width: 60px;
}

.ml5 {
    margin-left: 5px !important;
}

.mr5 {
    margin-right: 5px !important;
}

.card-type.bank {
    background-position: -37px -27px;
}
 #inbound-map,
    .flight-map {
        width: 100%;
        position: relative;
        border-radius: 8px;
        overflow: hidden;
        border: 1px solid #a5a5a5;
    }
@media only screen and (max-width: 600px) {
    .sticky-parent {
        position: relative !important;
        top: 20px !important;
    }

    #inbound-map,
    .flight-map {
        min-height: 100% !important;
    }
    .w-30{
        width: 100% !important;
    }
}
</style>
<style lang="scss">
#booking-modal {
    .modal-dialog {
        width: 380px;
        margin-top: 10%;
    }

    h3 {
        font-size: 24px;
    }

    .modal-content {
        border-radius: 5px;
    }

    .bookloader {
        display: inline-block;
        width: 100%;
        height: 105px;
        background-position: center;
        background-image: url('~@/assets/img/svg/loop.svg');
        background-repeat: no-repeat;
    }

    #booking-modal___BV_modal_footer_,
    #booking-modal___BV_modal_header_ {
        display: none !important;
    }

    #booking-modal___BV_modal_body_ {
        padding: 0;
    }
}

#failed-modal {
    .modal-dialog {
        width: 380px;
        margin-top: 10%;
    }

    h3 {
        font-size: 24px;
    }

    .modal-content {
        border-radius: 5px;
    }

    .bookloader {
        display: inline-block;
        width: 100%;
        height: 105px;
        background-position: center;
        background-image: url('~@/assets/img/svg/loop.svg');
        background-repeat: no-repeat;
    }

    #failed-modal___BV_modal_footer_,
    #failed-modal___BV_modal_header_ {
        display: none !important;
    }

    #failed-modal___BV_modal_body_ {
        padding: 0;
    }
}

#policy-modal {
    .modal-dialog {
        width: 380px;
        margin-top: 10%;
    }

    h3 {
        font-size: 24px;
    }

    .modal-content {
        border-radius: 5px;
    }

    .bookloader {
        display: inline-block;
        width: 100%;
        height: 105px;
        background-position: center;
        background-image: url('~@/assets/img/svg/loop.svg');
        background-repeat: no-repeat;
    }

    #policy-modal___BV_modal_footer_,
    #policy-modal___BV_modal_header_ {
        display: none !important;
    }

    #policy-modal___BV_modal_body_ {
        padding: 0;
    }
}
.fl-itinerary>div:last-child {
    border-left: 1px dashed #f0eeee;
    border-right: 0px;
}

.fl-itinerary>div:first-child {
    border-right: 1px dashed #f0eeee;
    border-left: 0px;
}
.rtl .fl-itinerary>div:last-child {
    border-right: 1px dashed #f0eeee;
    border-left: 0px;
}

.rtl .fl-itinerary>div:first-child {
    border-left: 1px dashed #f0eeee;
    border-right: 0px;
}

.text-center {
    text-align: center !important;
}


.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: inherit;
    font-weight: 600;
    line-height: 1.1;
    color: inherit;
}

.section_padding {
    font-size: 14px;
    letter-spacing: .01em;
}
</style>
<style>
.fl-img-con {
    display: inline-block;
    height: 50px;
    width: 50px;
    line-height: 60px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    background-color: #fff;
    text-align: -webkit-center;
}

.layover {
    text-align: center;
    padding: 10px 0;
    border-left-color: transparent;
    border-right-color: transparent;
    background-color: rgba(230, 240, 255, .7);
    margin: 0 15px;
    color: #507bbc;
}

.mb15 {
    margin-bottom: 15px !important;
}

.p20 {
    padding: 20px;
}

.itinerary-content {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px 10px;
    box-shadow: -4px -5px 14px rgba(0, 0, 0, 0.08), 5px 8px 16px rgba(0, 0, 0, 0.08);
}

.mb20 {
    margin-bottom: 20px !important;
}

.mt15 {
    margin-top: 15px !important;
}

.mb15 {
    margin-bottom: 15px !important;
}

.fl-ico.negative {
    background-position: -115px -45px;
}

.new-bold-light {
    font-weight: 600;
    font-style: normal;
    color: #0b6938;
    font-size: 18px;
}

.bold {
    font-weight: 600;
    font-style: normal;
}

.mt10 {
    margin-top: 10px !important;
}

.fl-trip {
    display: inline-block;
    vertical-align: middle;
    margin-right: inherit;
    margin-left: 15px;
}

.mb20 {
    margin-bottom: 20px !important;
}

.mt15 {
    margin-top: 15px !important;
}

.no-margin {
    margin: 0;
}
</style>