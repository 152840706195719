import {
    createRouter,
    createWebHistory
} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import FlightBookingPassengers from '../views/FlightBookingPassengers.vue'
import BookingConfirmationView from '../views/BookingConfirmationView.vue'
import ErrorView from '../views/ErrorView.vue'
import SuccessView from '../views/SuccessView.vue'
import NotFound from '../components/NotFound.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/flight-booking-passengers',
        name: 'flight-booking-passengers',
        component: FlightBookingPassengers
    },
    {
        path: '/booking-confirmation/:checkoutId?',
        name: 'booking-confirmation',
        component: BookingConfirmationView
    },
    {
        path: '/error',
        name: 'error',
        component: ErrorView
    },
    {
        path: '/successful-booking',
        name: 'successful-booking',
        component: SuccessView
    },
    {
        path: '/:cathAll(.*)',
        name: 'NotFound',
        component: NotFound
    }
]


const scrollBehavior = (to, from, savedPosition) => {
    return savedPosition ||
        to.meta.scrollPos || {
            top: 0,
            left: 0
        }
}

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior,
})

router.beforeEach((to, from, next) => {
    from.meta.scrollPos && (from.meta.scrollPos.top = window.scrollY)
    return next()
})

export default router